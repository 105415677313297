import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Our agile process is here to add value to your product. Find out how agile software development helps our customers in terms of accelerated execution, innovative planning and flexible change response.
We provide a more versatile planning strategy, enhancement through an improved prototype, frequent status updates, and fast response by means of agile software development tools. All this ensures you deliver your project on time and on budget.`}</p>
    </PageDescription>
    <Row mdxType="Row">
      <Column colMd={4} colLg={12} mdxType="Column">
        <h2>{`Agile Development Process`}</h2>
        <div style={{
          backgroundColor: '#343444',
          padding: '2rem'
        }}>
          <br /><br />
          <img {...{
            "src": "/images/lifecycle.svg",
            "alt": "Lifecycle"
          }}></img>
        </div>
        <hr></hr>
        <h2>{`Agile Software Development Methodology`}</h2>
        <div style={{
          backgroundColor: '#343444',
          padding: '2rem'
        }}>
          <img {...{
            "src": "/images/sprint.svg",
            "alt": "sprint"
          }}></img>
        </div>
        <br /><br />
        <p>{`Here’s what our agile methodology looks like in practice:`}</p>
        <ul>
          <li parentName="ul">{`Design sprint – generates value in concept form and prototype; `}</li>
          <li parentName="ul">{`Next sprints – work increments on the product; `}</li>
          <li parentName="ul">{`Releases united sprints – a higher incremental level;`}</li>
          <li parentName="ul">{`We are building a roadmap to see the whole picture together with Epics and Stories.`}</li>
        </ul>
        <p>{`We use Scrum, Kanban and XP to make it as fast as it can:`}</p>
        <ul>
          <li parentName="ul">{`check hypothesis; `}</li>
          <li parentName="ul">{`sales generate; `}</li>
          <li parentName="ul">{`momentum.`}</li>
        </ul>
        <br /><br />
        <hr></hr>
        <h2>{`What is an agile development team?`}</h2>
        <div style={{
          backgroundColor: '#343444',
          padding: '2rem'
        }}>
          <img {...{
            "src": "/images/scrum.svg",
            "alt": "scrum"
          }}></img>
        </div>
        <br /><br />
        <p>{`Agile Wizbord teams are developing software, which ensures that customers can quickly adopt a flexible approach to the plan, improved prototyping, regular status reports and fast responses to changing circumstances. This means that we are providing consistent solutions for time and budget.`}</p>
        <p>{`Our software development experts can easily integrate into any agile team. Wizbord may provide either a fully-managed agile (dedicated) team or extend its existing home team. As long as the resources that our clients need are dedicated and reliable, our expertise must remain. Typically, 1 or more software developers will work within a dedicated team, one QA Tester, one UX / UI Designer, a Project Manager, one Scrum Master, one TAA and one Solution Architect. If a member leaves, we will substitute him, and the team keeps track of his speed of delivery, without penalizing the customer from a cost perspective.`}</p>
        <p>{`Our flexible approach to software development enables structured and cross-functional teams of experts to work together.`}</p>
        <ul>
          <li parentName="ul">{`The Wizbord agile teams use tools and methods based on the needs and goals of the client. Continuous analysis, development and refinement of dynamic staff;`}</li>
          <li parentName="ul">{`Clearly defined backdrop evolving over time Regular meetings and customer communication, including sprint planning, careful planning and retrospective;`}</li>
          <li parentName="ul">{`Self-organization and accounting;`}</li>
          <li parentName="ul">{`Customer service.`}</li>
        </ul>
        <img {...{
          "src": "/images/challenge.svg",
          "alt": "challenge"
        }}></img>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      